
// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.



// export const environment = {
//   production: true,
//   baseUrl: 'http://localhost:4599/',
//   imagesUrl: 'http://localhost:4599/',
//   userUrl: 'http://localhost:4599/images/users/',
//   googleMapKey: '',
//   couponUrl: 'http://localhost:4599/images/coupons/',
// };

// export const environment = {
//   production: true,
//   baseUrl: 'http://34.232.2.249:4599/',
//   imagesUrl: 'http://34.232.2.249:4599/',
//   userUrl: 'http://34.232.2.249:4599/images/users/',
//   couponUrl: 'http://34.232.2.249:4599/images/coupons/',
//   googleMapKey: '',
// };

export const environment = {
  production: true,
  baseUrl: 'https://app.match2play.com/',
  imagesUrl: 'https://app.match2play.com/',
  userUrl: 'https://app.match2play.com/images/users/',
  couponUrl: 'https://app.match2play.com/images/coupons/',
  googleMapKey: '',
};

// export const environment = {
//   production: true,
//   baseUrl: 'http://34.192.195.201/',
//   imagesUrl: 'http://34.192.195.201/',
//   userUrl: 'http://34.192.195.201/images/users/',
//   couponUrl: 'http://34.192.195.201/images/coupons/',
//   googleMapKey: '',
// };



// export const environment = {
//   production: true,
//   baseUrl: 'http://18.213.224.163/',
//   imagesUrl: 'http://18.213.224.163/',
//   userUrl: 'http://18.213.224.163/images/users/',
//   couponUrl: 'http://18.213.224.163/images/coupons/',
//   googleMapKey: '',
// };

// export const environment = {
//   production: false,
//   baseUrl: 'http://34.232.2.249:4100/',
//   imagesUrl: 'http://34.232.2.249:4100/',
//   googleMapKey: '',
// };



